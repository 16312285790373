html {
  background-color: rgb(255, 255, 255);
}

.center {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.splash {
  margin: 20px;
}

p.splash {
  font-size: 28pt;
  margin-top: revert;
  text-align: center;
}

p, a {
  font-size: 18pt;
  text-align: justify;
}

.card {
  margin-bottom: 25px;
}
